import React, { useEffect, useState } from "react";
import Layout from "../../components/Layout";
import SEO from "../../components/SEO";
import Card from "../../components/Cards";
import { Link } from "gatsby";
import CardFull from "../../components/Cards/CardFull";
import InputSearch from "../../components/Input/InputSearch";
import ModalNew from "./components/ModalNew";
import requests from "../../services/requests";
import profile from "../../services/profile";
import ModalRemove from "./components/ModalRemove";
import ModalEdit from "./components/ModalEdit";
import EditIcon from "../../assets/svgs/pencil.inline.svg";
import { FaTrashAlt } from "react-icons/fa";

const HelpCenterPage = () => {
    const [openModalNew, setOpenModalNew] = useState(false);
    const [openModalEdit, setOpenModalEdit] = useState(false);
    const [openModalRemove, setOpenModalRemove] = useState(false);
    const [activeItem, setActiveItem] = useState([]);
    const [list, setList] = useState([]);

    const actionModalNew = () => {
        setOpenModalNew(!openModalNew);
    };

    const actionModalEdit = () => {
        setOpenModalEdit(!openModalEdit);
    };

    const actionModalRemove = () => {
        setOpenModalRemove(!openModalRemove);
    };

    const loadList = () => {
        requests.listFaqs().then(response => {
            setList(response);
        });
    };

    useEffect(() => {
        loadList();
    }, []);

    return (
        <Layout>
            <ModalNew
                actionModal={actionModalNew}
                openModal={openModalNew}
                handleSuccess={() => {
                    actionModalNew();
                    loadList();
                }}
            />
            <ModalEdit
                actionModal={actionModalEdit}
                openModal={openModalEdit}
                activeItem={activeItem}
                handleSuccess={() => {
                    actionModalEdit();
                    loadList();
                }}
            />
            <ModalRemove
                actionModal={actionModalRemove}
                openModal={openModalRemove}
                activeItem={activeItem}
                handleSuccess={() => {
                    actionModalRemove();
                    loadList();
                }}
            />
            <SEO title="Help Center" />
            <CardFull className="justify-between">
                <h3 className="text-roxo_oficial">
                    {profile.role === "ROLE_SYSTEM_ADMIN" && (
                        <button
                            onClick={actionModalNew}
                            className="m-5 px-4 py-1 border rounded-lg border-roxo_oficial text-lg"
                        >
                            Postar
                        </button>
                    )}
                    Help Center
                </h3>
                <div className="w-2/5 flex items-center">
                    <InputSearch placeholder="buscar por processo, empresa ou ref." />
                </div>
            </CardFull>
            <div className="px-8 py-12 overflow-auto">
                {list.map(item => (
                    <Card className="flex px-24 py-16 mb-4">
                        <div className="w-4/6">
                            <h4 className="mb-4 ">{item.title}</h4>
                            {item.videoUrl && (
                                <>
                                    <div className="flex">
                                        <div className="w-1/2 mr-4">
                                            <iframe
                                                width="100%"
                                                height="315"
                                                src={item.videoUrl}
                                                frameborder="0"
                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                allowfullscreen
                                            ></iframe>
                                        </div>
                                        <div className="w-1/2">
                                            <p
                                                className="font-normal mb-5"
                                                dangerouslySetInnerHTML={{ __html: item.content }}
                                            ></p>
                                            <div className="flex justify-end">
                                                <Link to={`/help-center/detalhes/${item.identifier}`}>
                                                    <p className="text-roxo_oficial cursor-pointer">+ ver mais</p>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}
                            {!item.videoUrl && (
                                <>
                                    <p className="font-normal mb-5" dangerouslySetInnerHTML={{ __html: item.content }}></p>
                                    <div className="flex justify-end">
                                        <Link to={`/help-center/detalhes/${item.identifier}`}>
                                            <p className="text-roxo_oficial cursor-pointer">+ ver mais</p>
                                        </Link>
                                    </div>
                                </>
                            )}
                        </div>
                        {profile.role === "ROLE_SYSTEM_ADMIN" && (
                            <div className="w-2/6 text-right">
                                <button
                                    className="p-2 mt-2 rounded-md bg-roxo_oficial_light mr-4"
                                    onClick={() => {
                                        setActiveItem(item);
                                        actionModalEdit();
                                    }}
                                >
                                    <EditIcon />
                                </button>
                                <button
                                    className="p-2  mt-2 rounded-md bg-canal_vermelho"
                                    onClick={() => {
                                        setActiveItem(item);
                                        actionModalRemove();
                                    }}
                                >
                                    <FaTrashAlt color="#fff" />
                                </button>
                            </div>
                        )}
                    </Card>
                ))}
            </div>
        </Layout>
    );
};

export default HelpCenterPage;
